.list {
	list-style-type: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	margin: 0;
	padding: 0;
}

.list_item {
	cursor: pointer;
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 24px;
	width: calc(50% - 12px);
	opacity: .6;
	transition-duration: .3s;
	transition-timing-function: ease;
	transition-property: opacity, border-color;
	box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
	border: 2px solid #ffffff;
}

.list_item:hover {
	opacity: 1;
	border-color: #3e68b5;
}

.list_item:nth-child(odd) {
	margin-right: 24px;
}

.list_item:nth-last-child(-n+2) {
	margin-bottom: 0;
}

.aspect_ratio_sizing_parent {
	padding-top: calc(100% * 200 / 350);
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	    justify-content: center;
	-ms-flex-align: center;
	    align-items: center;
}

.aspect_ratio_sizing_child {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	    align-items: center;
	-ms-flex-pack: center;
	    justify-content: center;
}

.list_item_image {
	vertical-align: top;
	object-fit: cover;
	width: 100%;
	height: 100%;
}
