.container {
  margin: 0 auto;
}

.callout_target {
	/* move these to page callout */
	background: #29333e;
  position: absolute;
	padding: 10px;
	box-sizing: border-box;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	color: white;
	font-family: Roboto, sans-serif;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	    justify-content: center;
	-ms-flex-align: center;
	    align-items: center;
  font-size: 21px;
  font-weight: 400;
  cursor: default;
}

.callout_popper {
	font-family: Roboto, sans-serif;
  position: absolute;
  color: white;
  background: #29333e;
	width: 220px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.14px;
	padding: 8px;
  box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  z-index: 999;
}

.callout_popper_arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 14px;
  border-color: #29333e;
}

.callout_popper[x-placement^="top"] {
  margin-bottom: 14px;
}

.callout_popper[x-placement^="top"] .callout_popper_arrow{
  border-width: 10px 10px 0 10px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -10px;
  left: calc(50% - 10px);
  margin-top: 0;
  margin-bottom: 0;
}

.callout_popper[x-placement^="bottom"] {
  margin-top: 14px;
}

.callout_popper[x-placement^="bottom"] .callout_popper_arrow {
  border-width: 0 10px 10px 10px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  top: -10px;
  left: calc(50% - 10px);
  margin-top: 0;
  margin-bottom: 0;
}

.callout_popper[x-placement^="right"] {
  margin-left: 14px;
}

.callout_popper[x-placement^="right"] .callout_popper_arrow {
  border-width: 10px 10px 10px 0;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  left: -10px;
  top: calc(50% - 10px);
  margin-left: 0;
  margin-right: 0;
}

.callout_popper[x-placement^="left"] {
  margin-right: 14px;
}

.callout_popper[x-placement^="left"] .callout_popper_arrow{
  border-width: 10px 0 10px 10px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  right: -10px;
  top: calc(50% - 10px);
  margin-left: 0;
  margin-right: 0;
}
