.container {
	margin: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	    align-items: center;
	-ms-flex-direction: column;
	    flex-direction: column;
}

.image {
	width: 100%;
	height: 100%;
	position: absolute;
}

.image_container {
	position: relative;
	width: 100%;
}

.image_dropshadow {
	box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
}
