.visible_nearby {
  width: 100%;
  color: #FFF;
  margin: 0;
  visibility: hidden;
  height: 0;
  width: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.visible_nearby img,
.visible_nearby video,
.visible_nearby :global(.rsSlide) {
   -webkit-backface-visibility: hidden;
           backface-visibility: hidden;
   will-change: transform;
   overflow: visible;
}
.visible_nearby :global(.rsSlide) img,
.visible_nearby :global(.rsSlide) video  {
  opacity: 0.45;
  transition: all 0.3s ease-out;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
.visible_nearby :global(.rsActiveSlide) img,
.visible_nearby :global(.rsActiveSlide) video  {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.visible_nearby :global(.rsOverflow) {
  cursor: default !important;
}
.visible_nearby :global(.rsSlide video) {
  width: 100%;
}
.visible_nearby :global(.rsVideoContainer) {
  height: 100%;
}
.image_with_dropshadow_container :global(.rsImg) {
  border-radius: 2px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
}
.royal_slider_initialized {
  visibility: visible;
  /*height: 0 !important;*/
  width: auto;
}
.figcaption {
  margin-top: 38px;
}

