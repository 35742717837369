@-webkit-keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.container {
	display: -ms-flexbox;
	display: flex;
	height: 100%;
}

.play_button_overlay {
	background: rgba(241, 243, 244, .95);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	    align-items: center;
	-ms-flex-pack: center;
	    justify-content: center;
	z-index: 1;
}

.play_button_overlay_playing {
	background: rgba(241, 243, 244, .15);
}

.play_button {
	border-radius: 50%;
	width: 84px;
	height: 84px;
	background: rgba(0,0,0,.2);
	padding: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	    align-items: center;
	-ms-flex-pack: center;
	    justify-content: center;
	cursor: pointer;
	border: 3px solid #9FAEB7;
	background: rgba(255, 255, 255, .5);
	box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
	outline: none;
	-webkit-animation-name: fade-in;
	        animation-name: fade-in;
	-webkit-animation-duration: .15s;
	        animation-duration: .15s;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: ease-in;
	        animation-timing-function: ease-in;
}

.play_button:hover {
	border-color: #778C99;
}

.play_button_symbol {
  width: 0; 
  height: 0;
	display: -ms-flexbox;
	display: flex;
	border-top: 15px solid transparent;
  border-left: calc(2 * 15px * 0.866) solid;
  border-bottom: 15px solid transparent;
  position: relative;
  left: 4px;
  border-left-color: #9FAEB7;
}

.play_button:hover .play_button_symbol {
	border-left-color: #778C99;
}

.pause_button_symbol {
	display: -ms-flexbox;
	display: flex;
	border-right: 6px solid #9FAEB7;
	border-left: 6px solid #9FAEB7;
	width: 8px;
	height: 26px;
}

.play_button:hover .pause_button_symbol {
	border-right-color: #778C99;
	border-left-color: #778C99;
}

