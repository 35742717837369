.card_container {
	padding: 0;
  margin: 0;
  margin-left: 0;
  margin-right: 0;
	list-style-type: none;
}

.card {
	background: #edf1f4;
	padding: 8px 24px;
	margin-bottom: 24px;
}

.card:last-child {
  margin-bottom: 0;
}
