.page_container {
	background: #ececec;
	margin: 0 auto;
}

.component_list {
	list-style-type: none;
	margin: 0 auto;
	margin: 0 auto;
	padding: 24px 0;
}

.component_list_item {
	margin: 0;
	background: white;
	padding: 24px;
	margin: 24px;
}

.selector_text {
	font-family: Roboto, sans-serif;
	background: black;
	color: white;
	padding: 6px 8px;
	display: inline;
}

.selector_text_wrapper {
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 24px;
}
