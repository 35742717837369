.container {
	display: -ms-flexbox;
	display: flex;
	border-radius: 4px;
	margin-bottom: 24px;
}
.image_container {
	width: 50%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	    align-items: center;
}
.image {
	width: 100%;
}
