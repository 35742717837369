.icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    background: #ffffff;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    z-index: 100;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
    opacity: .9;
	position: absolute;
	top: 20px;
	right: 20px;
}
