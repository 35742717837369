@-webkit-keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.container {
	text-align: center;
	-webkit-animation-name: fade-in;
	        animation-name: fade-in;
	-webkit-animation-duration: .5s;
	        animation-duration: .5s;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: ease-in;
	        animation-timing-function: ease-in;
}
