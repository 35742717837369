.outermost_container {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	    flex-direction: column;
}

.pane_container {
	display: -ms-flexbox;
	display: flex;
}

.left_pane {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	    flex-direction: column;
	-ms-flex: 5;
	    flex: 5;
}

.right_pane {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	    flex-direction: column;
	-ms-flex: 3;
	    flex: 3;
}

@media (max-width: 600px) {
	.pane_container {
		-ms-flex-direction: column;
		    flex-direction: column;
	}

	.left_pane {
		margin-bottom: 24px;
	}
}
