.outer_container {
	margin: 0 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	    flex-direction: column;
	width: 100%;
}

.inner_container {
}

.aspect_ratio_sizing_parent {
	position: relative;
}

.images_container {
	margin: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	display: -ms-flexbox;
	display: flex;
}

.images_container_flipped {
	-ms-flex-direction: row-reverse;
	    flex-direction: row-reverse;
}

.left_image_container,
.right_image_container {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	    align-items: center;
	-ms-flex-pack: center;
	    justify-content: center;
	overflow: hidden;
	-ms-flex-direction: column;
	    flex-direction: column;
	box-sizing: border-box;
	box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
	height: 100%;
}

.images_container .left_image_container {
	margin-right: 12px;
}

.images_container .right_image_container {
	margin-left: 12px;
}

.images_container_flipped .left_image_container {
	margin-right: 0; /* override */
	margin-left: 12px;
}

.images_container_flipped .right_image_container {
	margin-left: 0; /* override */
	margin-right: 12px; /* override */
}

.left_image,
.right_image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
