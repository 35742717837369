.container {
	margin: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	    align-items: center;
	-ms-flex-direction: column;
	    flex-direction: column;
}

.image {
	width: 100%;
	/* NOTE this should be unset or auto, but due to a safari bug this works instead to preserve aspect ratio. */
	height: 100%;
 	-webkit-user-select: none;
 	   -moz-user-select: none;
 	    -ms-user-select: none;
 	        user-select: none;
}

.image_container {
	position: relative;
}

.image_container_gray_background {
	background-color: #f5f9fa;
}

.image_dropshadow {
	box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
}

.hover_overlay {
	position: absolute;
	pointer-events: none;
	background-size: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

