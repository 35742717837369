@media all {
	.content_width {
		width: 773px;
		max-width: 100%;
	}
	.content_width_max_wide {
		max-width: 1100px;
	}
	.padding_top_spacing {
		padding-top: 60px !important;
	}
	.padding_bottom_spacing {
		padding-bottom: 60px !important;
	}
	.padding_right_spacing {
		padding-right: 60px !important;
	}
	.padding_left_spacing {
		padding-left: 60px !important;
	}
	.margin_top_spacing_small {
		margin-top: 24px !important;
	}
	.margin_top_spacing {
		margin-top: 60px !important;
	}
	.margin_bottom_spacing {
		margin-bottom: 60px !important;
	}
	.content_side_margins {
		margin-left: 24px !important;
		margin-right: 24px !important;
	}
	.content_side_padding {
		padding-left: 24px !important;
		padding-right: 24px !important;
	}
	.author_note_footer {
		margin-top: 160px;
		margin-bottom: 100px;
		text-align: center;
	}
	.colored_dot_legend_container {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: justify;
		    justify-content: space-between;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		margin: 0;
		padding: 0;
	}
	.colored_dot_legend_dot {
		display: block;
		width: 27px;
		height: 27px;
		-ms-flex-negative: 0;
		    flex-shrink: 0;
		border-radius: 50%;
		background: blue;
		margin-right: 12px;
	}
	.download_button {
		color: #464D56;
		cursor: pointer;
		display: inline-block;
		font-family: Roboto, sans-serif;
		font-size: 18px;
		font-weight: 600;
		text-decoration: none;
		letter-spacing: 0.5px;
		line-height: 32px;
		padding: 18px 52px;
		box-sizing: border-box;
		border-radius: 4px;
		border: solid 2px #3e68b5;
		background-color: #ffffff;
	}
	.figure_group_inner_container {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: justify;
		    justify-content: space-between;
		margin: 0 auto;
	}
	.figure_group_image_container {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		    justify-content: center;
	}
	.figure_group_image_container:not(:last-child) {
		margin-right: 24px;
	}
	.figure_showcase_child_wrapper:not(:last-child) {
		margin-bottom: 24px;
	}
	.page_nav_footer {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: justify;
		    justify-content: space-between;
		margin: 80px auto;
		padding: 0 80px;
		box-sizing: border-box;
	}
	.link {
		color: #545C65;
		font-family: Roboto, sans-serif;
		font-size: 18px;
		font-weight: 400;
		letter-spacing: 0.5px;
		line-height: 32px;
		margin: 0;
	}
	.lightbox_carousel_figure_wrapper {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		padding: 60px;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		    justify-content: center;
	}
	.ordered_list_card_topic, .ordered_list_card_details {
		font-family: Roboto;
		font-size: 18px;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.78;
		letter-spacing: 0.5px;
		color: #303030;
	}
	.ordered_list_card_topic {
		font-weight: 600;
		display: inline;
	}
	.ordered_list_card_details {
		font-weight: 400;
		margin: 0;
		display: inline;
	}
	.project_cta_card_button {
		opacity: 0.8;
		border-radius: 4px;
		cursor: pointer;
		border: none;
		background-color: rgba(255, 255, 255, .8);
		font-family: Roboto, sans-serif;
		font-weight: 600;
		letter-spacing: 0.14px;
		color: #545C65;
		margin: 0 0 60px 0;
		text-decoration: none;
	}
	.project_cta_card_button:hover {
		background-color: rgba(255, 255, 255, 1);
	}
	.project_cta_card_display {
		text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		font-family: Roboto, sans-serif;
		font-size: 34px;
		font-weight: 500;
		letter-spacing: 0.25px;
		color: #ffffff;
		margin: 60px 0 32px 0;
	}
	.project_cta_card_right_content {
		padding: 0 5%;
		width: 50%;
		box-sizing: border-box;
		text-align: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		    flex-direction: column;
		-ms-flex-align: center;
		    align-items: center;
		-ms-flex-pack: center;
		    justify-content: center;
	}
	.project_intro_card_pane_container {
		display: -ms-flexbox;
		display: flex;
	}
	.project_intro_card_left_pane_spacing {
		padding-right: 24px;
	}
	.project_intro_card_right_pane_top_section_spacing {
		margin-bottom: 24px;
	}
	.page_nav_footer_link_previous {
		text-decoration: none;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		    flex-direction: column;
		-ms-flex-align: start;
		    align-items: flex-start;
	}
	.page_nav_footer_link_next {
		text-decoration: none;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		    flex-direction: column;
		-ms-flex-align: end;
		    align-items: flex-end;
	}
	.author_credit {
		color: #555c64;
		font-family: Roboto, sans-serif;
		font-size: 14px;
		font-weight: normal;
		letter-spacing: 0.47px;
		line-height: 16px;
		margin-bottom: 14px;
	}
	.author_email {
		color: #555c64;
		font-family: Roboto, sans-serif;
		font-size: 14px;
		font-weight: 900;
		letter-spacing: 0.47px;
		line-height: 16px;
	}
	.bold {
		color: #545C65;
		font-family: Roboto, sans-serif;
		font-size: 16px;
		font-weight: 800;
		letter-spacing: 0.44px;
		line-height: 28px;
		margin: 0;
	}
	.caption_1 {
		color: #545C65;
		font-family: Roboto, sans-serif;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 0.44px;
		line-height: 16px;
		text-align: center;
		margin: 0;
	}
	.caption_2 {
		color: #899aa4;
		font-family: Roboto, sans-serif;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.4px;
		line-height: 16px;
		margin: 12px 0 0 0;
		text-align: center;
	}
	.colored_dot_legend_text {
		display: block;
		color: #545C65;
		font-family: Roboto, sans-serif;
		font-size: 18px;
		font-weight: 400;
		letter-spacing: 0.5px;
		line-height: 32px;
	}
	.contact_blurb {
		color: #92A3AD;
		font-family: Roboto, sans-serif;
		font-weight: 400;
		font-size: 12px;
	}
	.h1 {
		color: #29333E;
		font-family: Martel, serif;
		font-size: 30px;
		font-weight: 900;
		line-height: normal;
		letter-spacing: normal;
		margin-top: 0px;
		margin-right: 0px;
		margin-bottom: 24px;
		margin-left: 0px;
	}
	.h2 {
		color: #29333E;
		font-family: Roboto, sans-serif;
		font-size: 22px;
		font-weight: 500;
		letter-spacing: 0.17px;
		line-height: normal;
		margin: 0 0 24px 0;
	}
	.h3 {
		color: #29333E;
		font-family: Roboto, sans-serif;
		font-size: 18px;
		font-weight: 500;
		letter-spacing: 0.14px;
		line-height: normal;
		margin: 0 0 12px 0;
	}
	.logo {
		color: #29333E;
		font-family: Martel, serif;
		font-size: 24px;
		font-weight: 700;
		line-height: 28px;
		margin: 0;
	}
	.logo_subhead {
		color: #92A3AD;
		font-family: Roboto, sans-serif;
		font-weight: 400;
		line-height: normal;
		margin: 0;
		font-size: 14px;
	}
	.overline {
		color: #ABB8C0;
		font-family: Roboto, sans-serif;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 2.1px;
		line-height: 16px;
		text-transform: uppercase;
		margin-top: 0px;
		margin-right: 0px;
		margin-bottom: 24px;
		margin-left: -24px;
	}
	.page_heading {
		color: #29333E;
		font-family: Martel, serif;
		font-size: 38px;
		font-weight: 900;
		line-height: 28px;
		letter-spacing: 0;
		margin: 80px 0 12px 0;
	}
	.page_subhead {
		color: #545C65;
		font-family: Roboto, sans-serif;
		font-size: 18px;
		font-weight: 400;
		line-height: 32px;
		letter-spacing: .5px;
		margin: 0;
	}
	.page_top_nav_author_link {
		color: #29333E;
		font-family: Martel, serif;
		font-size: 20px;
		font-weight: 800;
		text-decoration: none;
	}
	.page_top_nav_link {
		color: #545C65;
		display: inline-block;
		font-family: Martel, serif;
		font-weight: 800;
		line-height: 27px;
		text-decoration: none;
	}
	.page_top_nav_link:hover {
		color: #29333E;
	}
	.page_top_nav_link_active {
		color: #29333E;
	}
	.page_top_nav_container {
		background: #ffffff;
		position: fixed;
		z-index: 101;
		top: 0;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: justify;
		    justify-content: space-between;
		-ms-flex-align: center;
		    align-items: center;
		transition: background 1s;
		box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
		padding: 15px 80px;
	}
	.page_top_nav_container_scroll_at_top {
		background: rgba(255, 255, 255, .5);
		box-shadow: none;
	}
	.page_top_nav_link_list_item {
		margin: 0 20px;
	}
	.page_top_nav_link_list_item:last-child {
		margin-right: 0;
	}
	.paragraph {
		color: #545C65;
		font-family: Roboto, sans-serif;
		font-size: 18px;
		font-weight: 400;
		letter-spacing: 0.5px;
		line-height: 32px;
		margin: 0;
	}
	.paragraph_margin_top {
		margin-top: 60px;
	}
	.paragraph_margin_bottom {
		margin-bottom: 60px;
	}
	.paragraph_margin_top_small {
		margin-top: 24px;
	}
	.page_nav_footer_link_previous_text {
		text-transform: uppercase;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		    align-items: center;
		font-family: Roboto, sans-serif;
		font-weight: 600;
		color: #ABB8C0;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 2.1px;
	}
	.page_nav_footer_link_previous_text::before {
		content: '';
		display: inline-block;
		color: #29333E;
		font-size: 26px;
		border-left: 2px solid #ABB8C0;
		border-bottom: 2px solid #ABB8C0;
		-webkit-transform: rotate(45deg);
		        transform: rotate(45deg);
		width: 8px;
		height: 8px;
		line-height: 26px;
		font-weight: 300;
		margin-right: 10px;
	}
	.page_nav_footer_link_next_text {
		text-transform: uppercase;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		    align-items: center;
		font-family: Roboto, sans-serif;
		font-weight: 600;
		color: #ABB8C0;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 2.1px;
		-ms-flex-direction: row-reverse;
		    flex-direction: row-reverse;
	}
	.page_nav_footer_link_next_text::before {
		content: '';
		display: inline-block;
		color: #29333E;
		font-size: 26px;
		border-top: 2px solid #ABB8C0;
		border-right: 2px solid #ABB8C0;
		-webkit-transform: rotate(45deg);
		        transform: rotate(45deg);
		width: 8px;
		height: 8px;
		line-height: 26px;
		font-weight: 300;
		margin-left: 10px;
	}
	.page_nav_footer_link_project_name {
		font-family: Roboto, sans-serif;
		color: #000000;
		font-size: 18px;
		font-weight: 600;
		letter-spacing: 0.14px;
		margin-top: 16px;
	}
	.quote_1 {
		color: #29333E;
		font-family: Roboto, sans-serif;
		font-size: 27px;
		font-style: italic;
		font-weight: 500;
		line-height: normal;
		letter-spacing: 0.2px;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		    align-items: center;
		margin-top: 40px;
		margin-bottom: 60px;
	}
	.quote_1::before {
		content: '';
		display: inline-block;
		width: 4px;
		height: 50px;
		margin-right: 20px;
		background-color: #c9d1d7;
	}
	.quote_1::after {
		content: '';
	}
	.quote_2 {
		color: #202020;
		font-family: Martel, serif;
		font-size: 18px;
		font-weight: 800;
		line-height: normal;
		letter-spacing: normal;
	}
	.quote_2::before {
		content: '';
	}
	.quote_2::after {
		content: '';
	}
	.top_nav_link {
		color: #333333;
		display: inline-block;
		font-family: Roboto, sans-serif;
		font-weight: 400;
		line-height: 32px;
		letter-spacing: 0.5px;
		margin-bottom: 24px;
		text-decoration: none;
		font-size: 18px;
		position: relative;
	}
	.top_nav_link::after {
		content: '';
		display: inline-block;
		height: 12px;
		background: rgba(51, 106, 212, 0.2);
		position: absolute;
		bottom: 2px;
		left: -12px;
		width: 100%;
		padding: 0 12px;
		transition-duration: .6s;
		transition-timing-function: ease;
		transition-property: opacity;
		opacity: 0;
	}
	.top_nav_link_hovered {
		color: #000000;
		font-weight: 500;
	}
	.top_nav_link_hovered::after {
		opacity: 1;
	}
	.top_nav_link:hover {
		color: #000000;
		font-weight: 500;
	}
	.top_nav_link:hover::after {
		opacity: 1;
	}
	.top_nav_link_active {
	}
}
@media (max-width: 414px) {
	.padding_top_spacing {
		padding-top: 40px !important;
	}
	.padding_bottom_spacing {
		padding-bottom: 40px !important;
	}
	.padding_right_spacing {
		padding-right: 24px !important;
	}
	.padding_left_spacing {
		padding-left: 24px !important;
	}
	.margin_top_spacing {
		margin-top: 40px !important;
	}
	.margin_bottom_spacing {
		margin-bottom: 40px !important;
	}
	.colored_dot_legend_container {
		-ms-flex-direction: column;
		    flex-direction: column;
	}
	.colored_dot_legend_dot {
		width: 16px;
		height: 16px;
	}
	.download_button {
		font-size: 14px;
	}
	.figure_group_inner_container_stack_on_tablet_mobile {
		-ms-flex-direction: column;
		    flex-direction: column;
	}
	.figure_group_inner_container_stack_on_tablet_mobile .figure_group_image_container:not(:last-child) {
		margin-bottom: 24px;
		margin-right: 0;
	}
	.page_nav_footer {
		padding: 0 24px;
		max-width: 773px;
	}
	.link {
		font-size: 14px;
	}
	.lightbox_carousel_figure_wrapper {
		padding: 0;
	}
	.ordered_list_card_topic, .ordered_list_card_details {
		font-size: 14px;
		line-height: 24px;
	}
	.project_cta_card_button {
		font-size: 14px;
		padding: 12px 18px;
	}
	.project_cta_card_display {
		font-size: 18px;
	}
	.caption_1 {
		font-size: 12px;
		line-height: 14px;
		margin: 0 24px;
	}
	.colored_dot_legend_text {
		font-size: 12px;
		line-height: 16px;
	}
	.h1 {
		font-size: 24px;
		margin-bottom: 14px;
	}
	.h2 {
		font-size: 18px;
	}
	.overline {
		margin-bottom: 14px;
		font-size: 12px;
	}
	.page_heading {
		font-size: 28px;
		line-height: 20px;
		letter-spacing: 0;
	}
	.page_subhead {
		font-size: 12px;
		line-height: 14px;
		padding: 0 24px;
	}
	.page_top_nav_author_link {
		font-size: 16px;
	}
	.page_top_nav_link {
		font-size: 14px;
		line-height: 24px;
	}
	.page_top_nav_link_list_item_hidden_on_mobile {
		display: none;
	}
	.page_top_nav_container {
		padding: 16px 24px;
	}
	.paragraph {
		font-size: 14px;
		line-height: 24px;
	}
	.paragraph_margin_top {
		margin-top: 40px;
	}
	.paragraph_margin_bottom {
		margin-bottom: 40px;
	}
	.quote_1 {
		font-size: 18px;
	}
}
@media (min-width: 415px) and (max-width: 812px) {
	.padding_top_spacing {
		padding-top: 50px !important;
	}
	.padding_bottom_spacing {
		padding-bottom: 50px !important;
	}
	.padding_right_spacing {
		padding-right: 50px !important;
	}
	.padding_left_spacing {
		padding-left: 50px !important;
	}
	.margin_top_spacing {
		margin-top: 50px !important;
	}
	.margin_bottom_spacing {
		margin-bottom: 50px !important;
	}
	.download_button {
		font-size: 16px;
	}
	.figure_group_inner_container_stack_on_tablet_mobile {
		-ms-flex-direction: column;
		    flex-direction: column;
	}
	.figure_group_inner_container_stack_on_tablet_mobile .figure_group_image_container:not(:last-child) {
		margin-bottom: 24px;
		margin-right: 0;
	}
	.page_nav_footer {
		padding: 0 24px;
		max-width: 773px;
	}
	.link {
		font-size: 16px;
	}
	.lightbox_carousel_figure_wrapper {
		padding: 20px;
	}
	.caption_1 {
		margin: 0 24px;
	}
	.colored_dot_legend_text {
		font-size: 14px;
		line-height: 20px;
	}
	.h1 {
		font-size: 24px;
		margin-bottom: 16px;
	}
	.h2 {
		font-size: 18px;
	}
	.overline {
		margin-bottom: 16px;
	}
	.page_subhead {
		padding: 0 24px;
	}
	.page_top_nav_author_link {
		font-size: 18px;
	}
	.page_top_nav_container {
		padding: 16px 24px;
	}
	.paragraph {
		font-size: 16px;
	}
	.paragraph_margin_top {
		margin-top: 50px;
	}
	.paragraph_margin_bottom {
		margin-bottom: 50px;
	}
}
@media (min-width: 415px) and (max-width: 600px) {
	.project_cta_card_button {
		font-size: 16px;
		padding: 14px 20px;
	}
	.project_cta_card_display {
		font-size: 22px;
	}
}
@media (min-width: 601px) and (max-width: 800px) {
	.project_cta_card_button {
		font-size: 16px;
		padding: 16px 22px;
	}
	.project_cta_card_display {
		font-size: 28px;
	}
}
@media (min-width: 801px) {
	.project_cta_card_button {
		font-size: 18px;
		padding: 20px 32px;
	}
	.project_cta_card_display {
		font-size: 34px;
	}
}
@media (max-width: 600px) {
	.project_intro_card_pane_container {
		-ms-flex-direction: column;
		    flex-direction: column;
	}
	.project_intro_card_left_pane_spacing {
		margin-bottom: 24px;
	}
}
@media (max-width: 821px) {
	.overline {
		margin-left: 0px;
	}
}
