.chrome {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	height: 100%;
	box-sizing: border-box;
}

.container {
	display: inline-block;
}