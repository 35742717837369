.container {
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	position: relative;
}

.image {
	max-width: 100%;
	-ms-flex-item-align: center;
	    align-self: center;
}

.callouts_list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.callout {
	margin: 0;
	padding: 0;
}
