.container {
	margin: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	    align-items: center;
	-ms-flex-direction: column;
	    flex-direction: column;
}

.video {
	width: 100%;
 	-webkit-user-select: none;
 	   -moz-user-select: none;
 	    -ms-user-select: none;
 	        user-select: none;
 	-ms-flex-item-align: center;
 	    align-self: center;
	/* NOTE this should be unset or auto, but due to a safari bug this works instead to preserve aspect ratio. */
}

.video_dropshadow {
	box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
}

.video_wrapper {
	position: relative;
}
