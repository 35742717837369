.nav_container {
	box-sizing: border-box;
	z-index: 2;
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	background: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
  display: none;
}

.nav_container_open {
	display: -ms-flexbox;
	display: flex;
}

.left_nav {
}

.project_items_ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.info_items_ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.author_heading {
	margin-bottom: 20px;
}

@media (max-width: 600px) {
	.nav_container {
		width: 100%;
		padding: 40px;
	}
}

@media (min-width: 601px) and (max-width: 1200px) {
	.nav_container {
		width: 33.33%;
		min-width: 300px;
		max-width: 480px;
		padding: 40px;
	}
}

@media (min-width: 1201px) {
	.nav_container {
		width: 33.33%;
		min-width: 300px;
		max-width: 480px;
		padding: 80px;
	}
}
