.image {
	margin: auto;
	max-height: 100%;
	max-width: 100%;
	pointer-events: auto;
}

.image:last-child {
/*	z-index: 1;*/
}

.prev_button,
.next_button {
	position: absolute;
	pointer-events: auto;
	z-index: 1;
	border: none;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	font-size: 30px;
	color: #999;
	font-weight: 400;
	outline: none;
	cursor: pointer;
	background: rgba(255, 255, 255, .8);
    top: calc(50% - 30px);
    padding: 0;
}

.prev_button:disabled,
.next_button:disabled {
	opacity: .3;
	cursor: default;
}

.prev_button {
	left: 0;
}

.next_button {
	right: 0;
}

.video {
	margin: auto;
	max-height: 100%;
	max-width: 100%;
	pointer-events: auto;
}

.pinch_zoom {
	text-align: center;
	overflow: visible;
}

.figure_wrapper {
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	outline: none;
}
