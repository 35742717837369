@-webkit-keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.container {
	margin: 80px 0 80px 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	    flex-direction: column;
	-webkit-animation-name: fade-in;
	        animation-name: fade-in;
	-webkit-animation-duration: .3s;
	        animation-duration: .3s;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: ease-in;
	        animation-timing-function: ease-in;
}
