.crossfading_scaling_image_image {
  width: 100%;
  height: 100%;
  background-position: left !important;
  /*transition: .5s ease; NOTE this makes it really slow for some reason */
  position: absolute;
  top: 0;
  background-size: cover;
}

.crossfading_scaling_image_container {
  padding-top: 100vh;
  position: relative;
  width: 100vw;
}

.crossfading_scaling_image_container_nav_closed {
  background-position: center !important;
  padding-top: calc(100% * 618 / 1114);
  position: relative;
}

.crossfading_scaling_image_container_transition {
  transition: .5s ease;
}

.cover_image_container {
  overflow: hidden;
  width: 100vw;
}

.nav_open_and_rested {
  height: 100vh;
}
