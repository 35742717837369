.container {
	margin: 0;
}

.compare_image_container {
	box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
}

.handle {
	width: 50px;
	height: 50px;
	width: 51px;
  height: 51px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: relative;
  z-index: 1;
}

.handle::before {
	content: '';
	display: inline-block;
	width: 16px;
	height: 3px;
	border-top: 2px solid #000000;
	border-bottom: 2px solid #000000;
}

.dropshadow_patch_container {
  height: 100%;
  position: absolute;
  overflow: hidden;
  margin-left: 50px;
}

.dropshadow_patch {
  height: 100%;
  width: 100px;
  background: white;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}
