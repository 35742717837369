.close_button {
	background: rgba(255, 255, 255, .8);
	cursor: pointer;
	outline: none;
	position: absolute;
	top: 0;
	right: 0;
	pointer-events: auto;
	z-index: 2;
	margin: 0;
	padding: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	    align-items: center;
	-ms-flex-pack: center;
	    justify-content: center;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	border: none;
}

.close_button_symbol {
	color: #999;
	font-size: 30px;
	position: relative;
	top: -3px;
}
